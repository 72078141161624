import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { Button, Divider, Form, Input, message, Row, Select, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import { useCallback, useMemo, useRef, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Helmet } from "react-helmet";
import deleteIcon from "../../assets/delete-icon.png";
import editIcon from "../../assets/edit-icon.png";
import addIcon from "../../assets/add.png";
import moreIcon from "../../assets/expand_more.png";
import API_BASE_URL from "../../env";
import { tab_access, tab_role } from "../../utils/commonConsts";
import './Settings.css';

const Settings = () => {
  const Token = localStorage.getItem('Token');
  const [activeTab, setActiveTab] = useState(tab_role);
  const [addRoleForm] = Form.useForm();
  const gridStyle = useMemo(() => ({ width: "100%" }), []);
  const accessGridStyle = useMemo(() => ({ width: "100%" }), []);
  const gridRef = useRef();
  const [show, setShow] = useState(false);
  const [roleRowData, setRoleRowData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState();

  const handleClose = () => {
    setShow(false);
    addRoleForm.resetFields();
  };
  const handleShow = () => {
    setIsEdit(false);
    setShow(true);
  };

  const onGridReady = useCallback((params) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/v1/cPanelRole/fetchAllCPanelRoles`, config)
      .then((res) => {
        setRoleRowData(res.data?.data);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
      });

    // Automatically size columns on grid ready
    params?.api.sizeColumnsToFit();
  }, []);

  const handleEditRole = (data) => {
    setIsEdit(true);
    setShow(true);
    setId(data.id);
    addRoleForm.setFieldsValue({
      cpanel_role: `${data.cpanel_role}`
    })

  }

  const handleDeleteRole = async (data) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    await axios
      .delete(`${API_BASE_URL}/api/v1/cPanelRole/deleteCPanelRole/${data.id}`, config)
      .then((res) => {
        message.success(res.data.data)
        onGridReady();
      })
      .catch((err) => {
        message.error(err.response?.data.data?.errors[0]);
      });
  }

  const accessRowData = [
    { modules: 'CUSTOMERS' },
    { modules: 'USERS' },
    { modules: 'SUBSCRIPTIONS' }
  ]

  const roleColumnDefs = [
    {
      field: "cpanel_role",
      headerName: "NAME",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      cellRenderer: (params) => {
        return (
          <div className="action-items">
            <img src={editIcon} alt="Edit" onClick={() => handleEditRole(params.data)} />
            <img src={deleteIcon} alt="Delete" onClick={() => handleDeleteRole(params.data)} />
          </div>
        )
      },
      pinned: 'right',  // This fixes the column to the right side
      suppressMovable: true,  // Prevent this column from being draggable
      lockPosition: true,
      sortable: false, // Optional: Disable sorting if not needed
      filter: false,
      resizable: false,
    }
  ]

  const accessColumnDefs = [
    {
      field: "modules",
      headerName: "MODULES",
      minWidth: 150
    },
    {
      field: "view",
      headerName: 'VIEW',
      cellRenderer: (params) => {
        return (
          <div className="table-select">
            <Select
              className="select-dropdown"
              defaultValue="All"
              onChange={handleChange}
              options={[
                { value: 'All', label: 'All' },
                { value: 'My Own', label: 'My Own' },
                { value: 'None', label: 'None' },
              ]}
              suffixIcon={<img src={moreIcon} alt="More Icon" />}
            />
          </div>
        )
      },
      minWidth: 360
    },
    {
      field: "edit",
      headerName: 'EDIT',
      cellRenderer: (params) => {
        return (
          <div className="table-select">
            <Select
              className="select-dropdown"
              defaultValue="All"
              onChange={handleChange}
              options={[
                { value: 'All', label: 'All' },
                { value: 'My Own', label: 'My Own' },
                { value: 'None', label: 'None' },
              ]}
              suffixIcon={<img src={moreIcon} alt="More Icon" />}

            />
          </div>
        )
      },
      minWidth: 360
    },
    {
      field: 'delete',
      headerName: 'DELETE',
      cellRenderer: (params) => {
        return (
          <div className="table-select">
            <Select
              className="select-dropdown"
              defaultValue="All"
              onChange={handleChange}
              options={[
                { value: 'All', label: 'All' },
                { value: 'My Own', label: 'My Own' },
                { value: 'None', label: 'None' },
              ]}
              suffixIcon={<img src={moreIcon} alt="More Icon" />}
            />
          </div>
        )
      },
      minWidth: 360
    }
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
    };
  }, []);

  const rowHeight = 52;
  const accessRowHeight = 74;

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSaveButton = async () => {
    const values = addRoleForm.getFieldsValue();

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    const payload = {
      cpanel_role: values.cpanel_role
    }
    if (isEdit) {
      await axios
        .put(`${API_BASE_URL}/api/v1/cPanelRole/${id}`, payload, config)
        .then((res) => {
          setShow(false);
          onGridReady();
          addRoleForm.setFieldsValue({
            cpanel_role: ''
          });
        })
        .catch((err) => {
          message.error(err.response?.data.data?.errors[0]);
        });
    } else {
      await axios
        .post(`${API_BASE_URL}/api/v1/cPanelRole/createCPanelRole`, payload, config)
        .then((res) => {
          setShow(false);
          onGridReady();
          addRoleForm.setFieldsValue({
            cpanel_role: ''
          });
        })
        .catch((err) => {
          message.error(err.response?.data.data?.errors[0]);
        });
    }
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) =>
        word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(' ');
  };

  const roleOptions = () => {
    const formattedData = roleRowData.map(item => ({
      value: `${toTitleCase(item.cpanel_role)}`,
      label: `${toTitleCase(item.cpanel_role)}`
    }));

    console.log(formattedData);

    return formattedData;
  }

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  return (
    <div className="settings-container">
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <div className="settings">
        <div className="settings-header">
          <Tabs
            defaultActiveKey={tab_role}
            onChange={onTabChange}
            className="custom-tabs"
          >
            <TabPane tab="Role" key={tab_role} />
            <TabPane tab="Access Control" key={tab_access} />
          </Tabs>

          <div className="search-container">
            {activeTab === tab_role &&
              <div className="add-role-container">
                <Button className="add-role" onClick={handleShow}>
                  <img src={addIcon} />Role
                </Button>
                <Offcanvas show={show} onHide={handleClose} placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{isEdit ? 'Edit' : 'Add'} Role</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Form
                      form={addRoleForm}
                      className="user-form"
                    >
                      <Row className="user-form-field">
                        <span className="field-header-text">Role Name
                          <span className="asterisk">*</span>
                        </span>
                        <Form.Item
                          name="cpanel_role"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the role name!",
                            },
                          ]}
                        >
                          <Input className="user-form-input" />
                        </Form.Item>
                      </Row>
                    </Form>
                  </Offcanvas.Body>

                  <div className="button-container">
                    <Button className="cancel-button" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button className="add-button" hidden={isEdit} onClick={handleSaveButton}>
                      Add
                    </Button>
                    <Button className="add-button" hidden={!isEdit} onClick={handleSaveButton}>
                      Save
                    </Button>
                  </div>

                </Offcanvas>
              </div>
            }
            <Input
              className="search-box"
              type="text"
              id="filter-text-box"
              placeholder="Search"
              onInput={onFilterTextBoxChanged}
              suffix={<SearchOutlined />}
            />
          </div>
        </div>
        <Divider plain></Divider>

        {activeTab === tab_role &&
          <div className="ag-theme-quartz role-data">
            <AgGridReact
              ref={gridRef}
              rowData={roleRowData}
              columnDefs={roleColumnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowHeight={rowHeight}
            />
          </div>
        }

        {activeTab === tab_access &&
          <div className="access-settings">
            <div className="role-select">
              <label>Select Role</label>
              <Select
                className="select"
                placeholder="Select"
                onChange={handleChange}
                options={roleOptions()}
                suffixIcon={<img src={moreIcon} alt="More Icon" />}
              />
            </div>
            <div className="ag-theme-quartz access-control-table">
              <AgGridReact
                ref={gridRef}
                rowData={accessRowData}
                columnDefs={accessColumnDefs}
                defaultColDef={defaultColDef}
                rowHeight={accessRowHeight}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Settings;