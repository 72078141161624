import { CloseOutlined, MoreOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Col, Dropdown, Form, Input, Menu, message, Modal, Radio, Row, Select, Spin, Upload } from "antd";
import { Divider, Tabs } from "antd/es";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Helmet } from "react-helmet";
import image1 from "../../assets/image1.jpg";
import addIcon from "../../assets/add.png";
import usflag from "../../assets/usflag.png";
import moreVertical from "../../assets/more_vert.png";
import moreIcon from "../../assets/expand_more.png";
import closeIcon from "../../assets/clear.png";
import API_BASE_URL from "../../env";
import { _status_ACTIVE, _status_DELETED, _status_INACTIVE, _status_PENDING, _status_SUBSCRIPTIONS, _status_TRAIL } from "../../utils/commonConsts";
import CellWithOverflowTooltip from "../CellWithOverflowTooltip/CellWithOverflowTooltip";
import "./Customers.css";
import { Link } from "react-router-dom";
import { routes_clinic_details } from "../../utils/RoutingConsts";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import parsePhoneNumberFromString from "libphonenumber-js";
import OffCanvas from "./offcanvas/OffCanvas";

const ActionsRenderer = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const Token = localStorage.getItem("Token");
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  const handleMenuClick = (e) => {
    if (e.target.innerHTML === "Approve") {
      const payload = {};
      axios
        .put(
          `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${props.data.id}&status=${_status_ACTIVE}`,
          payload,
          config
        )
        .then((res) => {
          message.success(res.data?.data);
          let tabActive = props.activeTab === _status_TRAIL ? _status_ACTIVE : props.activeTab;
          props.refreshData(tabActive);
        })
        .catch((err) => {
          message.error(err.response.data.data?.errors[0]);
        });;
    } else if (e.target.innerHTML === "Reject") {
      setModalOpen(true);
    }
  };

  const handleReject = () => {
    const payload = {};
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${props.data.id}&status=${_status_INACTIVE}`,
        payload,
        config
      )
      .then((res) => {
        message.success(res.data?.data);
        let tabActive = props.activeTab === _status_TRAIL ? _status_ACTIVE : props.activeTab;
        props.refreshData(tabActive);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
      });;
  }

  const rejectOptions = [
    { value: 'Lack of documents', label: 'Lack of documents' },
    { value: 'Eligibility criteria', label: 'Eligibility criteria' },
    { value: 'Non medical establishment', label: 'Non medical establishment' },
  ]

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <div className="options">
        <div className="approve" value="approve" onClick={handleMenuClick}>
          Approve
        </div>
        <div className="reject" value="reject" onClick={handleMenuClick}>
          Reject
        </div>
      </div>

      <Modal
        className="reject-modal"
        open={isModalOpen}
        centered
        closable={false}
        footer={false}
      >
        <div className="modal-body">
          <div className="close" onClick={() => setModalOpen(false)}><img src={closeIcon} alt="Close" /></div>
          <div className="header">
            Reject Signup
          </div>
          <div className="body">
            <div className="modal-field">
              <label>Reason for Rejection
                <span className="asterisk">*</span>
              </label>
              <Select
                className="select-dropdown"
                placeholder="Select Reason"
                onChange={handleChange}
                options={rejectOptions}
                suffixIcon={<img src={moreIcon} alt="More Icon" />}
              />
            </div>
            <div className="modal-field">
              <label>Description
                <span className="asterisk">*</span>
              </label>
              <TextArea />
            </div>
          </div>
          <div className="buttons-container">
            <Button className="cancel" onClick={() => setModalOpen(false)}>Cancel</Button>
            <Button className="reject" onClick={handleReject}>Reject </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Customers = () => {
  const Token = localStorage.getItem("Token");
  const gridRef = useRef(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [activeTab, setActiveTab] = useState(_status_PENDING);
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(true);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(`${API_BASE_URL}/api/v1/clinic/clinics?status=${activeTab}`, config)
      .then((res) => {
        setRowData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
        setLoading(false);
      });
  }, []);

  const refreshData = (status) => {
    setRowData([]);
    setLoading(true);
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/clinics?status=${status}`, config)
      .then((res) => {
        setRowData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.data?.errors[0]);
        setLoading(false);
      });
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Detect the country code based on the phone number prefix
    const countryCode = phoneNumber.startsWith('91') ? 'IN' : 'US';

    // Parse the phone number with the detected country code
    const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    // Format the phone number in international format with the +{countrycode}
    return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
  };

  const handleDropdownMenuClick = (id, key) => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    const payload = {};
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/updateClinicStatus?id=${id}&status=${key}`,
        payload,
        config
      )
      .then((res) => {
        if (key === _status_DELETED) {
          message.success(' Clinic Deleted Successfully');
        }
        let tabActive = activeTab === _status_TRAIL ? _status_ACTIVE : activeTab;
        refreshData(tabActive);
      });
  };

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        message.success("Email copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy email!");
      });
  };

  const gridOptions = {
    suppressHorizontalScroll: false, // Enable horizontal scrolling
    overlayNoRowsTemplate: '<span class="custom-no-rows">No clinics to display</span>',
  }

  const columnDefs = [
    {
      field: "clinic_name",
      headerName: "CLINIC NAME",
      cellRenderer: (params) => {
        const { clinic_name, id } = params.data;
        return (
          <Row className="clinic-row-data-element">
            <Col>
              <img
                className="clinic-profile-pic"
                src={image1}
                alt="Profile Picture"
              />
            </Col>
            <Col className="clinic-name">
              <Link to={`${routes_clinic_details}/${id}`}><CellWithOverflowTooltip value={clinic_name} /></Link>
            </Col>
          </Row>
        );
      },
      flex: 2,
      minWidth: 230
    },
    {
      field: "contact_info",
      headerName: "POINT OF CONTACT",
      cellRenderer: (params) => {
        const { first_name, last_name, email } = params.data;

        return (
          <div className="row-data-element">
            <div className="full-name"><CellWithOverflowTooltip value={`${first_name} ${last_name}`} /></div>
            <div className="email" onClick={(() => copyToClipboard(email))}><CellWithOverflowTooltip value={email} /></div>
            {/* <div className="email">{email}</div> */}
          </div>
        );
      },
      flex: 2,
      minWidth: 230
    },
    {
      field: "phone_number",
      headerName: "PHONE NUMBER",
      cellRenderer: (params) => {
        const { phone_number } = params.data;
        return (
          <div className="row-data-element">
            <div className="phone">{formatPhoneNumber(phone_number)}</div>
          </div>
        );
      },
      flex: 1.5,
      minWidth: 150
    },
    {
      field: "created_at",
      headerName: "CREATED DATE",
      cellRenderer: (params) => {
        const { created_at } = params.data;
        const created_date = moment(created_at).format("DD MMM YYYY, h:mm A");
        return (
          <div className="row-data-element">
            <div className="created-date">{created_date}</div>
          </div>
        );
      },
      // flex: 1.5,
      minWidth: 150
    },
    {
      field: "status",
      headerName: "ACCOUNT STATUS",
      cellRenderer: (params) => {
        const { status } = params.data;
        let statusClass = "";

        if (status === "PENDING") {
          statusClass = "status-pending"; // Pending tab
        } else {
          statusClass = "status-active";  // Trial and subscriptions tab
        }

        return (
          <div className="row-data-element">
            <div className={`status ${statusClass}`}>
              {status === "PENDING" ? "PENDING" : "APPROVED"}
            </div>
          </div>
        );
      },
      flex: 1.25,
      minWidth: 165
    },
    ...(activeTab === _status_PENDING
      ? [
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            const { id } = params.data;
            return (
              <div className="actions-list">
                <ActionsRenderer {...params} refreshData={refreshData} activeTab={activeTab} />
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="view"><Link to={`${routes_clinic_details}/${id}`}>View Details</Link></Menu.Item>
                      <Menu.Item
                        key="deactivate"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_DELETED)
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={moreVertical} alt="More" className="more" />
                </Dropdown>
              </div>
            );
          },
          pinned: 'right',  // This fixes the column to the right side
          suppressMovable: true,  // Prevent this column from being draggable
          lockPosition: true,
          sortable: false, // Optional: Disable sorting if not needed
          filter: false,
          resizable: false,
          flex: 1.5,
          minWidth: 210
        }
      ] : []),
    ...(activeTab === _status_TRAIL
      ? [
        {
          field: "trial",
          headerName: "TRIAL ACCESS",
          cellRenderer: (params) => {
            const { plan_start_date, plan_end_date } = params.data;
            if (plan_start_date && plan_end_date) {
              const start = moment(plan_start_date);
              const end = moment(plan_end_date);
              const daysRemaining = end.diff(start, "days");

              if (daysRemaining > 0) {
                return (
                  <div className="row-data-element">
                    <div className="trial-days">{`${daysRemaining} days`}</div>
                  </div>
                );
              } else {
                return (
                  <div className="row-data-element">
                    <div className="expired">EXPIRED</div>
                  </div>
                );
              }
            } else {
              return (
                <div className="row-data-element">
                  <div className="login-pending">Login Pending</div>
                </div>
              );
            }
          },
          flex: 1,
          minWidth: 150
        },
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            const { id } = params.data;
            return (
              <div>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="revoke"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_INACTIVE)
                        }
                      >
                        Revoke Approval
                      </Menu.Item>
                      <Menu.Item key="view"><Link to={`${routes_clinic_details}/${id}`}>View Details</Link></Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_DELETED)
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={moreVertical} alt="More" className="more" />
                </Dropdown>
              </div>
            );
          },
          pinned: 'right',  // This fixes the column to the right side
          suppressMovable: true,  // Prevent this column from being draggable
          lockPosition: true,
          sortable: false, // Optional: Disable sorting if not needed
          filter: false,
          resizable: false,
          flex: 0.75,
          maxWidth: 100

        }
      ]
      : []),
    ...(activeTab === _status_SUBSCRIPTIONS
      ? [
        {
          field: "subscriptions",
          headerName: "SUBSCRIPTIONS",
          cellRenderer: (params) => {
            const { plan } = params.data;
            return (
              <div className="row-data-element">
                <div className="plan">{plan}</div>
              </div>
            );
          },
          flex: 1.5,
          minWidth: 150
        },
        {
          field: "actions",
          headerName: "ACTIONS",
          cellRenderer: (params) => {
            <CellWithOverflowTooltip value={params.value} />;
            const { id } = params.data;
            return (
              <div className="row-data-element">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="revoke"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_INACTIVE)
                        }
                      >
                        Revoke Approval
                      </Menu.Item>
                      <Menu.Item
                        key="renew"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_ACTIVE)
                        }
                      >
                        Renew
                      </Menu.Item>
                      <Menu.Item key="view"><Link to={`${routes_clinic_details}/${id}`}>View Details</Link></Menu.Item>
                      <Menu.Item
                        key="delete"
                        onClick={(key) =>
                          handleDropdownMenuClick(id, _status_DELETED)
                        }
                      >
                        Delete Clinic
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <img src={moreVertical} alt="More" className="more" />
                </Dropdown>
              </div>
            );
          },
          pinned: 'right',  // This fixes the column to the right side
          suppressMovable: true,  // Prevent this column from being draggable
          lockPosition: true,
          sortable: false, // Optional: Disable sorting if not needed
          filter: false,
          resizable: false,
          flex: 0.75,
          maxWidth: 100
        }
      ]
      : []),
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true
    };
  }, []);

  const rowHeight = 80;

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);


  const onFirstDataRendered = (params) => {
    params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
  };

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  const onTabChange = async (key) => {
    setActiveTab(key); // Set active tab
    setLoading(true);

    setTimeout(() => {
      if (key === _status_TRAIL) {
        refreshData(_status_ACTIVE)
      }
      else {
        refreshData(key);
      }
    }, 500);
  };


  return (
    <div className="customers-container">
      <Helmet>
        <title>Customer Dashboard</title>
      </Helmet>
      <div className="data-container">
        <div className="data-header">
          <Tabs
            defaultActiveKey={_status_PENDING}
            onChange={onTabChange}
            className="custom-tabs"
          >
            <TabPane tab="Signup Pending" key={_status_PENDING} />
            <TabPane tab="Trial" key={_status_TRAIL} />
            <TabPane tab="Subscribed" key={_status_SUBSCRIPTIONS} />
          </Tabs>
          <div className="search-container">
            <Button className="add-clinic" onClick={handleOpen}>
              <img src={addIcon} />Customer
            </Button>
            <Input
              className="search-box"
              type="text"
              id="filter-text-box"
              placeholder="Search"
              onInput={onFilterTextBoxChanged}
              suffix={<SearchOutlined />}
            />
          </div>
        </div>
        <Divider plain></Divider>
        {loading && (
          <Spin className="spinner" />

        )}
        {!loading && (
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              paginationAutoPageSize={true}
              pagination={true}
              paginationPageSize={5}
              onFirstDataRendered={onFirstDataRendered}
              tooltipShowDelay={0}
              rowSelection={rowSelection}
              rowHeight={rowHeight}
              gridOptions={gridOptions}
            />
          </div>
        )}
      </div>

      <OffCanvas show={show} setShow={setShow} mode={'add'} />
    </div >
  );
};

export default Customers;