import { ContainerOutlined, PlusOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Breadcrumb, Dropdown, message } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import profile from "../../assets/ellipse.png";
import ivoryLogo from "../../assets/ivory-logo.png";
import customersLogo from "../../assets/customerIcon.svg";
import usersLogo from "../../assets/patients.svg";
import subscriptionLogo from "../../assets/subscription.svg";
import settingsLogo from "../../assets/settings.svg";
import customersLogoActive from "../../assets/customersLogoActive.svg";
import usersLogoActive from "../../assets/usersLogoActive.svg";
import subscriptionLogoActive from "../../assets/subscriptionLogoActive.svg";
import settingsLogoActive from "../../assets/settingsLogoActive.svg";
import addIcon from "../../assets/add.png";
import notificationIcon from "../../assets/notifications_none.png";
import Customers from '../customers/Customers';
import Users from '../users/Users';
import "./Home.css";
import Subscriptions from '../subscriptions/Subscriptions';
import Settings from '../settings/Settings';
import { clinic_details, customers, routes_clinic_details, routes_customers, routes_settings, routes_subscriptions, routes_users, settings, subscriptions, users } from '../../utils/RoutingConsts';
import ClinicDetails from '../customers/clinic-details/ClinicDetails';
import API_BASE_URL from '../../env';
import axios from 'axios';

const { Header, Content, Sider } = Layout;

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [clinicName, setClinicName] = useState('');
    const [loading, setLoading] = useState(false);  // Loading state for fetching clinic name
    const userName = localStorage.getItem('name');

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleLogout = () => {
        navigate('/login');
        localStorage.removeItem('Token');
    };

    const userMenu = (
        <Menu>
            <Menu.Item key="1" onClick={handleLogout}>
                Log Out
            </Menu.Item>
        </Menu>
    );

    const breadcrumbMap = useMemo(() => ({
        [routes_customers]: 'CUSTOMERS',
        [routes_users]: 'USERS',
        [routes_subscriptions]: 'SUBSCRIPTIONS',
        [routes_settings]: 'SETTINGS',
        [routes_clinic_details]: 'CUSTOMERS' // Default breadcrumb for clinic details
    }), []);

    // Fetch clinic name when navigating to clinic details
    useEffect(() => {
        const path = location.pathname;

        if (path.startsWith(routes_clinic_details)) {
            const clinicId = path.split('/')[4];  // Assumes URL structure is /clinic-details/[clinicId]

            // Fetch the clinic name
            const fetchClinicName = async () => {
                setLoading(true);
                const Token = localStorage.getItem('Token');
                const config = {
                    headers: {
                        Authorization: `Bearer ${Token}`,
                    },
                };

                try {
                    const res = await axios.get(`${API_BASE_URL}/api/v1/clinic/${clinicId}`, config);
                    setClinicName(res.data.data.clinic_name);
                } catch (err) {
                    message.error('Error fetching clinic details');
                } finally {
                    setLoading(false);
                }
            };

            fetchClinicName();
        }
    }, [location.pathname]);

    const breadcrumbItems = useMemo(() => {
        const path = location.pathname;

        // Check if the path matches the clinic details route
        if (path.startsWith(routes_clinic_details)) {
            return [
                {
                    title: (
                        <span
                            onClick={() => navigate(routes_customers)}
                            className='bread-crumb-item'
                        >
                            CUSTOMERS
                        </span>
                    ),
                    link: routes_customers
                },  // Link to customers list
                { title: loading ? 'Loading...' : clinicName }   // Show loading while fetching clinic name
            ];
        }

        // Default breadcrumb behavior
        const breadcrumbLabel = breadcrumbMap[path] || '';
        return [
            { title: breadcrumbLabel }
        ];
    }, [location.pathname, breadcrumbMap, clinicName, loading]);

    // Create a map of pathnames to menu keys
    const menuKeyMap = useMemo(() => ({
        [routes_customers]: '1',
        [routes_users]: '2',
        [routes_subscriptions]: '3',
        [routes_settings]: '4'
    }), []);

    // Set the selected key based on the current pathname
    const selectedKey = menuKeyMap[location.pathname] || '1';

    return (
        <Layout className="home-layout">
            <Header className="top-navbar">
                <img className="logo" src={ivoryLogo} alt="Ivory Logo" />
                <div className='actions-container'>
                    <span className='quick-actions'>
                        <img src={addIcon}/> Quick Actions
                    </span>
                    <img src={notificationIcon} alt='Notifications' className='notifications'/>
                    <div className="user">
                        <div className="user-profile">
                            <span className="user-name">{userName}</span>
                            <span className="user-role">Administrator</span>
                        </div>
                        <Dropdown overlay={userMenu} trigger={['click']}>
                            <img className="profile-pic" src={profile} alt="Profile Picture" />
                        </Dropdown>
                    </div>
                </div>
            </Header>
            <Layout className='home-content'>
                <Sider className="side-navbar" collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
                    <Menu
                        selectedKeys={[selectedKey]}  // Dynamically set selected key based on path
                        mode="inline"
                        inlineCollapsed={collapsed}
                        className="side-navbar-menu"
                    >
                        <Menu.Item key="1">
                            <img
                                src={selectedKey === '1' ? customersLogoActive : customersLogo}
                                alt="Customers Icon"
                            />
                            <Link to={routes_customers}>Customers</Link>
                        </Menu.Item>

                        <Menu.Item key="2">
                            <img
                                src={selectedKey === '2' ? usersLogoActive : usersLogo}
                                alt="Users Icon"
                            />
                            <Link to={routes_users}>Users</Link>
                        </Menu.Item>

                        <Menu.Item key="3">
                            <img
                                src={selectedKey === '3' ? subscriptionLogoActive : subscriptionLogo}
                                alt="Subscriptions Icon"
                            />
                            <Link to={routes_subscriptions}>Subscriptions</Link>
                        </Menu.Item>

                        <Menu.Item key="4">
                            <img
                                src={selectedKey === '4' ? settingsLogoActive : settingsLogo}
                                alt="Settings Icon"
                            />
                            <Link to={routes_settings}>Settings</Link>
                        </Menu.Item>
                    </Menu>
                </Sider>

                <Layout>
                    <Content className='content'>
                        <Breadcrumb className='breadcrumb' items={breadcrumbItems} /> {/* Dynamic breadcrumb */}
                        <Routes>
                            <Route path="/" element={<Navigate to={routes_customers} />} />
                            <Route path={customers} element={<Customers />} />
                            <Route path={users} element={<Users />} />
                            <Route path={subscriptions} element={<Subscriptions />} />
                            <Route path={settings} element={<Settings />} />
                            <Route path={clinic_details} element={<ClinicDetails />} />
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </Layout >
    );
};

export default Home;